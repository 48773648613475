import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tabletop from 'tabletop';
import slugify from 'slugify';

import ReportTable from './ReportTable';


export class ReportSpreadsheet extends Component {

  static propTypes = {
    sheet_id: PropTypes.string.isRequired
  }

  state = {
    isLoaded: false,
    sheet_id: this.props.sheet_id,
    sheet_url: this.props.sheet_url,
    sheet_data: [],
    columns: []
  }

  componentWillMount(){
    Tabletop.init({ 
      key: this.state.sheet_id,
      callback: data => {

        const firstRow = data[0];
        const _headers = Object.keys(firstRow);

        const columns = _headers.map( col => {
          return {
            Header: col,
            accessor: slugify(col.toLowerCase())
          }
        })

        const sheet_data = data.map( (row, i) => {
          var key, keys = Object.keys(row);
          var n = keys.length;
          var newobj={}
          while (n--) {
            key = keys[n];
            newobj[slugify(key.toLowerCase())] = row[key];
          }
          return newobj;
        })

        this.setState({
          sheet_data: sheet_data,
          columns: columns,
          isLoaded: true
        })
      },
      simpleSheet: true 
    })
  }

  render() {
    const {sheet_data, sheet_url, columns} = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col" style={{display: 'flex', justifyContent: 'flex-end', fontSize: '14px', padding: '10px'}}>
            <a href={sheet_url} target="_blank" rel="noopener noreferrer">View in Google Sheets</a>
          </div>
        </div>
        <div className="row">
        <ReportTable data={sheet_data} columns={columns}/>
        </div>
        
      </div>
    )
  }
}

export default ReportSpreadsheet
