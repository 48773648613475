import React from 'react';
import { Link } from 'react-router-dom';

import pointing_finger_right from '../../img/pointing-finger-right.svg';


export default function NextSection(props) {
  return (
    <div>
      <div className="row" style={{display: 'flex', flexDirection: 'column', textAlign: 'center', margin: '50px'}}>
        <Link to={`/section/${props.nextSection}`}>
            <div>
              <img src={pointing_finger_right} alt="next section" style={{ width: '300px'}} />
            </div>   
            <p style={{ fontSize: '1.5rem', fontWeight: '900', textTransform: 'uppercase' }} >Next Section: {props.nextSection}</p>
        </Link>
      </div>
    </div>
  )
}
