import React, { Component } from 'react'

export class ImgPreview extends Component {

  render() {

    const {source_url, title, alt_text} = this.props.img;

    return (
      <div>
        <img className="img-fluid" src={source_url} title={title} alt={alt_text}/>
      </div>
    )
  }
}

export default ImgPreview
