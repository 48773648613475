import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from "react-table";
import 'react-table/react-table.css'
 
class ReportTable extends Component {
  static propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
  };

  
  render() {
    const {data, columns} = this.props;
    return <ReactTable data={data} columns={columns} minRows={1}/>
  }
}
 
export default ReportTable